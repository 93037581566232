<template>
    <div class="grid-nogutter" style="background: #1D1C21; color: #FFF;">
        <div class="col-12">
            <div class="flex py-2">
                <div class="ml-auto mr-2">
                    <p class="cm-copyright">COPYRIGHT © 2022 Cloud Media IT Solution Ltd, All rights Reserved . version 1.0.0</p>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.cm-copyright{
    font-size: .8rem;
}
</style>