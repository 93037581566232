<template>
    <NavBar />
    <TabMenu :model="items" :activeIndex="activeIndex"/>
    <main style="margin-top: 1rem;">
        <router-view></router-view>
    </main>
    <The-Footer /> 
</template>

<script>
export default {
    data() {
		return {
		}
	},
    computed: {
      items() {
         return [
            {label: '主頁', icon: 'pi pi-fw pi-home', to: '/home/'+this.$store.state.clientAlias },
            {label: '課程時間表', icon: 'pi pi-fw pi-calendar', to: '/home/class'}
        ];
      }
    }
}
</script>

<style>
.p-menubar-button {
    display: none!important;
}
</style>