<template>
  <v-calendar 
  is-expanded 
  :attributes='attributes'
  :min-date='new Date()'
  @update:from-page="get_month_classes_mobile"
  class= "lg:hidden" />

  <div class="hidden lg:block" style="margin: 1rem 0;">
    <The-Card>
      <template #content>
        <FullCalendar ref="fullCalendar" :options="options" />
      </template>
    </The-Card>
  </div>
</template>

<script>
import '@fullcalendar/core/vdom' // solves problem with Vite
// import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import axios from 'axios';

export default {
  data() {
    const todos = [
      // {
      //   description: 'Take Noah to basketball practice.',
      //   isComplete: true,
      //   dates: { weekdays: 6 }, // Every Friday
      //   color: 'red',
      // },
    ];
    const dates = [];
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    return {
      incId: todos.length,
      todos,
      dates,
      attrs: [
        {
          key: 'today',
          highlight: {
            color: 'purple',
            fillMode: 'solid',
            contentClass: 'italic',
          },
          dates: new Date(year, month, 12),
        },
        {
          highlight: {
            color: 'purple',
            fillMode: 'light',
          },
          dates: new Date(year, month, 13),
        },
        {
          highlight: {
            color: 'purple',
            fillMode: 'outline',
          },
          dates: new Date(year, month, 14),
        },
      ],
      options: {
        plugins:[dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
            left: 'prev,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        height: "auto",
        themeSystem: 'bootstrap5',
        editable: false,
        selectable:true, 
        selectMirror: true, 
        dayMaxEvents: true,
        events: [],
        customButtons: {
          prev: { // this overrides the prev button
            text: "PREV", 
            click: () => {
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.prev();
              console.log("eventPrev", calendarApi.getDate().getMonth());
              this.get_month_classes(calendarApi.getDate().getMonth());
            }
          },
          next: { // this overrides the next button
            text: "NEXT",
            click: () => {
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.next();
              console.log("eventNext", calendarApi.getDate().getMonth());
              this.get_month_classes(calendarApi.getDate().getMonth());
            }
          }
        }
      }
    }
  },
  computed: {
    attributes() {
      return [
        {
          key: 'today',
          highlight: {
            color: 'gray',
            fillMode: 'solid',
          },
          dates: new Date(),
        },
        ...this.dates.map(date => ({
          dot: date.dot,
          dates: date.class_date
        })),
        // Attributes for todos
        ...this.todos.map(todo => ({
          dates: todo.dates,
          // dot: {
          //   color: todo.color,
          //   class: todo.isComplete ? 'opacity-75' : '',
          // },
          popover: {
            label: todo.description,
          },
          customData: todo,
        })),
      ];
    },
  },
  created(){
    this.get_classes();
    this.get_classes_mobile();
  },
  methods: {
    get_classes(){
      axios.post('https://www.cloudfit.pro/api/v1/web/classes/', {
        center_id: this.$store.state.centerID,
      })
      .then((response) => {
          // handle success
          // console.log(response.data);
          this.options.events = response.data.classes;
      })
      .catch((error) => {
          // handle error
          console.log(error);
      });
    },
    get_month_classes(month){
      axios.post('https://www.cloudfit.pro/api/v1/web/classes/month/', {
        center_id: this.$store.state.centerID,
        month: month + 1
      })
      .then((response) => {
          // handle success
          // console.log(response.data);
          this.options.events = response.data.classes;
      })
      .catch((error) => {
          // handle error
          console.log(error);
      });
    },
    get_classes_mobile(){
      axios.post('https://www.cloudfit.pro/api/v1/web/classes/mobile/', {
        center_id: this.$store.state.centerID,
      })
      .then((response) => {
          // handle success
          response.data.dates.forEach(element => {
            element.dates = new Date(element.year, element.month, element.day);
          });
          response.data.classes.forEach(element => {
            element.dates = new Date(element.year, element.month, element.day);
          });
          this.dates = response.data.dates;
          this.todos = response.data.classes;
      })
      .catch((error) => {
          // handle error
          console.log(error);
      });
    },
    get_month_classes_mobile(page){
      axios.post('https://www.cloudfit.pro/api/v1/web/classes/mobile/month/', {
        center_id: this.$store.state.centerID,
        month: page.month
      })
      .then((response) => {
          // handle success
          response.data.dates.forEach(element => {
            element.dates = new Date(element.year, element.month, element.day);
          });
          response.data.classes.forEach(element => {
            element.dates = new Date(element.year, element.month, element.day);
          });
          this.dates = response.data.dates;
          this.todos = response.data.classes;
      })
      .catch((error) => {
          // handle error
          console.log(error);
      });
    },
    pageChange(page) {
      console.log('onDateRangeChange', page);
    }
  }
}
</script>

<style>
.fc-button-group .fc-button:not(.fc-prev-button):not(.fc-next-button){
  background-color: transparent!important;
  border-color: #1c374c!important;
  padding: .55rem 1.5rem;
  color: #1c374c!important;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event{
    background: transparent!important;
    border-color: transparent!important;
}
.fc.fc-theme-standard .fc-toolbar .fc-button{
    background: transparent;
    border-color: transparent;
} 
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active{
    background: rgba(28,55,76,.2)!important;
    border-color: #1c374c!important;
}
.fc .fc-toolbar .fc-next-button .fc-icon, .fc .fc-toolbar .fc-prev-button .fc-icon{
  color: #6e6b7b;
}
.fc .fc-button .fc-icon{
  vertical-align: middle;
  font-size: 1.5em;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover{
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:not(.fc-prev-button):not(.fc-next-button):enabled:hover{
  background-color: transparent;
  border-color: #1c374c;
}
</style>