<template>
    <main class="auth-bg flex justify-content-center align-items-center">
        <The-Card class="m-auto w-4">
            <template #title>
                
            </template>
            <template #content>
                <div class="grid">
                    <div class="col-12">
                        <h3>
                            Welcome to CloudFit Pro! 👋
                        </h3>
                        <p class="mb-5">Please sign-in to your account and start the adventure</p>
                        <span class="p-float-label mb-4">
                            <InputText id="username" type="text" class="w-full p-inputtext-sm" v-model="email" />
                            <label for="username">電郵地址</label>
                        </span>
                        <span class="p-float-label p-input-icon-right w-full mb-5">
                            <i class="pi pi-eye" />
                            <InputText id="username" type="text" class="w-full p-inputtext-sm" v-model="password" />
                            <label for="username">密碼</label>
                        </span>
                        <router-link to="/home/1" style="text-decoration: underline;color: #1D1C21;">忘記密碼</router-link>
                    </div>
                    <div class="col-12 lg:col-6">
                        <The-Button label="登入" class="p-button-raised p-button-secondary w-full" />
                    </div>
                    <div class="col-12 lg:col-6">
                        <The-Button label="未有賬戶？" class="p-button-outlined p-button-secondary w-full" />
                    </div>
                </div>   
            </template>
        </The-Card>
    </main>
</template>

<script>
export default {
    data() {
        return {
            email: null,
            password: null
        }
    }
}
</script>

<style scoped>
    .auth-bg{
        height: 100vh;
        background-image: url('@/assets/bg-1.jpg');
        background-size: cover;
    }
</style>