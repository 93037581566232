import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createStore } from 'vuex'

import ToastService from 'primevue/toastservice'
import PrimeVue from 'primevue/config'
import Button from 'primevue/button'
import Menubar from 'primevue/menubar'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import TabMenu from 'primevue/tabmenu'
import FullCalendar from '@fullcalendar/vue3/dist/FullCalendar'
import Image from 'primevue/image';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Galleria from 'primevue/galleria';
import Dialog from 'primevue/dialog';
import Fieldset from 'primevue/fieldset';
import SelectButton from 'primevue/selectbutton';
import Dropdown from 'primevue/dropdown';

import 'primevue/resources/themes/lara-light-blue/theme.css'      //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'                           //icons
import '/node_modules/primeflex/primeflex.css'

import NavBar from './components/NavBar.vue'
import FooTer from './components/FooTer.vue'
import HomePage from './components/HomePage.vue'
import ClassPage from './components/ClassPage.vue'
import MainLayoutPage from './components/MainLayoutPage.vue'
import LoginPage from './components/LoginPage.vue'
import RegisterPage from './components/RegisterPage.vue'

import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';

import { library } from '@fortawesome/fontawesome-svg-core'

import { faHatWizard } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faHatWizard)

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', redirect: '/home' },
        { 
          path: '/home',
          component: MainLayoutPage,
          children: [
            { path: 'class', component: ClassPage },
            { path: ':clientAlias', component: HomePage },
          ] 
        },
        { path: '/login', component: LoginPage },
        { path: '/register', component: RegisterPage }
        
    ]
})
// Create a new store instance.
const store = createStore({
    state () {
      return {
        clientAlias: '',
        centerId: 0,
        clientId: 0,
        logo: ''
      }
    },
    mutations: {
      setClientAlias(state, payload) {
        state.clientAlias = payload;
      },
      setClientId(state, payload) {
        state.clientId = payload;
      },
      setCenterId(state, payload){
        state.centerId = payload;
      },
      setLogo(state, payload){
        state.logo = payload;
      }
    }
})

const app = createApp(App)
app.use(PrimeVue)
app.use(store)
app.use(ToastService)
app.use(VCalendar, {})

app.component('The-Footer', FooTer);
app.component('InputText', InputText);
app.component('The-Button', Button);
app.component('Menu-bar', Menubar);
app.component('The-Toast', Toast);
app.component('TabMenu', TabMenu);
app.component('FullCalendar', FullCalendar);
app.component('The-Image', Image)
app.component('The-Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('The-Card', Card)
app.component('The-Carousel', Carousel)
app.component('The-Galleria', Galleria)
app.component('DiaLog', Dialog)
app.component('FieldSet', Fieldset)
app.component('NavBar', NavBar)
app.component('SelectButton', SelectButton)
app.component('DropDown', Dropdown)
app.component('font-awesome-icon', FontAwesomeIcon)

app.use(router);

app.mount('#app')