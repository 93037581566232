<template>
    <div style="position:relative;">
        <div class="bg-image" :style="{'background-image': 'url('+center.banner+')'}"></div>
        <div class="banner container" :style="{'background-image': 'url('+center.banner+')'}"></div>
    </div>
    <div class="center-info">
        <div class="container">
            <div class="flex flex-column lg:flex-row">
                <div class="info-container hidden lg:flex">
                    <The-Avatar :image="center.web_icon"
                    class="mr-2" shape="circle" 
                    style="width: 150px;
                    height: 150px; 
                    min-width: 150px; 
                    min-height: 150px; 
                    position: absolute; 
                    left:0; 
                    top: -50px; 
                    border: 2px solid #1c384d;"/>
                </div>
                <div class="content hidden lg:flex">
                    <div>
                        <h2>{{ center.name }}</h2>
                    </div>
                    <div class="flex flex-row my-auto" style="margin-right: 20px;">
                        <The-Button :label="center.telephone"  class="p-button-rounded p-button-secondary p-button-sm ml-3" icon="pi pi-whatsapp"></The-Button>
                    </div>
                </div>
                <div class="info-container-mobile lg:hidden">
                    <The-Avatar :image="center.web_icon"
                    class="mr-2" shape="circle" 
                    style="width: 75px;
                    height: 75px;
                    min-width: 75px;
                    min-height: 75px;"/>

                    <div class="content">
                        <div>
                            <h2>{{ center.name }}</h2>
                        </div>
                    </div>
                </div>
                <!-- <div class="grid lg:hidden">
                    <div class="col">
                        <The-Button label="時段預約" class="p-button-cm mx-auto px-5 block w-full"></The-Button>
                    </div>
                    <div class="col">
                        <The-Button label="私人預約" class="p-button-cm mx-auto px-5 block w-full"></The-Button>
                    </div>
                </div>
                <div class="my-auto ml-auto hidden lg:flex">
                    <The-Button label="時段預約" icon="pi pi-check" class="p-button-cm ml-3 px-5"></The-Button>
                    <The-Button label="私人預約" icon="pi pi-calendar" class="p-button-cm ml-3 px-5"></The-Button>
                </div> -->
            </div>
        </div>
    </div>
    <div class="container">
        <div class="profile-info">
            <div class="grid">
                <div class="col-12 lg:col-5">

                    <The-Card style="margin-bottom: 2em;">
                        <template #title>
                            關於
                        </template>
                        <template #content>
                            <div class="flex" style="height: 250px;margin-bottom: 1rem;">
                                <!-- <iframe style="width: 100%;" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.5956658871896!2d114.17753311495531!3d22.331126485307585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404072d606fd57b%3A0x481e10f27e2eeed0!2sThe%20Square%2C%206%20Cambridge%20Rd%2C%20Kowloon%20Tsai!5e0!3m2!1sen!2shk!4v1621843261115!5m2!1sen!2shk" /> -->
                                <div style="width: 100%; display: flex;" v-html="center.google_map"></div>
                            </div>
                            <div class="flex">
                                <div style="padding: 6px;">
                                    <i class="pi pi-info-circle" style="font-size: 1.5rem"></i>
                                </div>
                                <div style="padding: 6px;">
                                    <p class="m-0">
                                        {{ center.self_intro }}
                                    </p>
                                </div>
                            </div>
                            <div class="flex">
                                <div style="padding: 6px;">
                                    <i class="pi pi-map-marker" style="font-size: 1.5rem"></i>
                                </div>
                                <div style="padding: 6px;">
                                {{ center.address }}
                                </div>
                            </div>
                            <div class="flex">
                                <div style="padding: 6px;">
                                    <i class="pi pi-whatsapp" style="font-size: 1.5rem"></i>
                                </div>
                                <div style="padding: 6px;">
                                {{ center.telephone }}
                                </div>
                            </div>
                            <div class="flex">
                                <div style="padding: 6px;">
                                    <i class="pi pi-inbox" style="font-size: 1.5rem"></i>
                                </div>
                                <div style="padding: 6px;">
                                    {{ center.email }}
                                </div>
                            </div>
                            <div class="flex">
                                <div style="padding: 6px;">
                                    <i class="pi pi-clock" style="font-size: 1.5rem"></i>
                                </div>
                                <div style="padding: 6px;">
                                    {{ center.timeslot }}
                                </div>
                            </div>
                        </template>
                    </The-Card>
                    <The-Card style="margin-bottom: 2em;">
                        <template #title>
                            社交媒體
                        </template>
                        <template #content>
                            <div style="text-align: center;" >
                                <!-- <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fvalleyfitstudio%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=false&appId" width="340" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> -->
                                <span v-html="center.fb_link"></span>
                            </div>
                        </template>
                    </The-Card>
                </div>
                <div class="col-12 lg:col-7">

                    <The-Card style="width: 25rem; margin-bottom: 2em;">
                        <template #title>
                            中心設施
                        </template>
                        <template #content>
                            <The-Galleria :value="images" :numVisible="5" :circular="true" :autoPlay="true" :transitionInterval="2500">
                                <template #item="slotProps">
                                    <img :src="slotProps.item.itemImageSrc" :alt="slotProps.item.alt" style="width: 100%" />
                                </template>
                                <template #thumbnail="slotProps">
                                    <img :src="slotProps.item.thumbnailImageSrc" :alt="slotProps.item.alt" style="width: 100%" />
                                </template>
                            </The-Galleria>
                        </template>
                    </The-Card>
                    <The-Card style="width: 25rem; margin-bottom: 2em;">
                        <template #title>
                            課程介紹
                        </template>
                        <template #content>
                            <div class="grid">
                                <div class="col-3" v-for="item in group_class" :key="item.id">
                                    <img :src="item.thumbnail" style="cursor: pointer; width: 150px;border: 1px solid transparent; border-radius: 10px;margin-right: 1rem;" @click="openModal" />
                                    <DiaLog header="CLASS" v-model:visible="displayModal" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
                                        <img :src="item.thumbnail" style="width: 100%;"/>
                                        <p class="m-0">{{item.description}}</p>
                                        <template #footer>
                                        </template>
                                    </DiaLog>
                                </div>
                            </div>
                        </template>
                    </The-Card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            center: {
                id: '',
                name: '',
                telephone: '',
                email: '',
                web_icon: '',
                timeslot: '',
                address: '',
                banner: '',
                fb_link: '',
                google_map: ''
            },
            displayModal: false,
            images: [
            ],
            responsiveOptions: [
                {
                    breakpoint: "1024px",
                    numVisible: 3,
                    numScroll: 3
                },
                {
                    breakpoint: "600px",
                    numVisible: 2,
                    numScroll: 2
                },
                {
                    breakpoint: "480px",
                    numVisible: 1,
                    numScroll: 1
                }
            ],
            group_class: [
            ]
        };
    },
    methods: {
        openModal() {
            this.displayModal = true;
        },
        get_center(){
            axios.post('https://www.cloudfit.pro/api/v1/web/center/', {
                client_alias: this.$store.state.clientAlias,
            })
            .then((response) => {
                // handle success
                console.log(response.data);
                this.center.telephone = response.data.data.telephone;
                this.center.address = response.data.data.zh_address;
                this.center.web_icon = response.data.data.web_icon;
                this.center.email = response.data.data.email;
                this.center.banner = response.data.data.banner;
                this.center.fb_link = response.data.data.fb_link;
                this.center.self_intro = response.data.data.self_intro;
                this.center.google_map = response.data.data.google_map;
                this.center.timeslot = response.data.data.timeslot;
                this.$store.commit('setCenterId', response.data.data.center_id);
                this.$store.commit('setLogo', response.data.data.logo);
            })
            .catch((error) => {
                // handle error
                console.log(error);
            });
        },
        get_client(){
            axios.post('https://www.cloudfit.pro/api/v1/web/client/', {
                client_alias: this.$store.state.clientAlias,
            })
            .then((response) => {
                // handle success
                console.log(response.data.data);
                this.center.name = response.data.data.client_name;
                this.$store.commit('setClientId', response.data.data.client_id);
                this.get_group_class();
            })
            .catch((error) => {
                // handle error
                console.log(error);
            });
        },
        get_images(){
            axios.post('https://www.cloudfit.pro/api/v1/web/images/', {
                client_alias: this.$store.state.clientAlias,
            })
            .then((response) => {
                // handle success
                console.log('images', response);
                response.data.forEach(element => {
                    this.images.push({
                        "itemImageSrc": "/images/"+this.$store.state.clientAlias+"/"+element,
                        "thumbnailImageSrc": "/images/"+this.$store.state.clientAlias+"/"+element,
                    });
                });
            })
            .catch((error) => {
                // handle error
                console.log(error);
            });
        },
        get_group_class(){
            axios.post('https://www.cloudfit.pro/api/v1/web/group_class/', {
                client_id: this.$store.state.clientId,
            })
            .then((response) => {
                // handle success
                console.log('get_group_class', response);
                response.data.data.forEach(element => {
                    this.group_class.push({
                        "thumbnail": element.thumbnail,
                        "description": element.description
                    });
                });
            })
            .catch((error) => {
                // handle error
                console.log(error);
            });
        },
        setClientAlias() {
            this.$store.commit('setClientAlias', this.$route.params.clientAlias);
        },
    },
    mounted(){
        this.setClientAlias();
        this.get_client();
        this.get_center();
        this.get_images();
    }
}
</script>

<style scoped>
.bg-image {
  /* The image used */
  /* background-image: url('@/assets/IMG_5400.jpg'); */
  
  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);
  
  /* Full height */
  height: 400px; 
  
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
/* carousel imaegs */
.product-item-content {
    border-radius: 3px;
    margin: .3rem;
    text-align: center;
    padding: 2rem 0;
}

.product-image {
    width: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.p-card{
    width: 100%!important;
}
.profile-info{
    padding-top: 30px;
    padding-bottom: 30px;
}

.banner{
    min-height: 400px;
    /* background-image: url('@/assets/IMG_5400.jpg'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media (min-width: 992px) {
  .banner {
    min-width: 200px;
  }
}
.center-info{
    /* border-bottom: 1px solid #ef6032;
    box-shadow: 0 -120px 100px 0 rgb(0 0 0 / 15%); */
}
.info-container{
    display: flex;
    position: relative;
    min-height: 90px;
    padding-left: 150px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.info-container-mobile{
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    align-items: center;
}
.content{
    margin: auto 0;
    padding-left: 20px;
    display: flex;
    flex-direction: row;
}

</style>