<template>
   <Menu-bar>
      <template #start>
         <router-link to="/home/DEM"><img alt="logo" :src="logo" height="60" class="mr-2"></router-link>
      </template>
      <template #end>
         <DropDown v-model="selectedCenter" :options="centers" optionLabel="name" optionValue="code" @change="onChange($event)" />
         <!-- <router-link to="/register"><The-Button label="註冊" class="hidden lg:inline-flex p-button-secondary mr-2"/></router-link>
         <router-link to="/login"><The-Button label="登入" class="p-button-secondary"/></router-link> -->
      </template>
   </Menu-bar>   
</template>

<script>
import axios from 'axios';

export default {
   data() {
      return {
         center: {
            logo: '',
            alias: '',
            zh_name: ''
         },
         selectedCenter: 'HQ',
         centers: [
            {name: '總店', code: 'HQ'}
         ]
      }
   },
   methods: {
      onChange() {
         console.log('onChange');
      },
      get_center(){
         axios.post('https://www.cloudfit.pro/api/v1/web/center/', {
            client_id: this.$route.params.clientId,
         })
         .then((response) => {
            // handle success
            this.center.logo = response.data.data.logo;
            this.center.alias = response.data.data.alias;
            this.center.zh_name = response.data.data.zh_name;
         })
         .catch((error) => {
            // handle error
            console.log(error);
         });
      },
   },
   mounted(){
      // const centerId = this.$route.params.centerId;
      this.get_center();
   },
   computed: {
      logo() {
         return this.$store.state.logo;
      }
   }
}
</script>

<style>
.p-menubar-button{
   display: none;
}
.p-menubar{
   background-color: white;
}
.p-dropdown{
   border: 0;
}
.p-focus{
   outline: none;
   box-shadow: none!important;
}
.p-dropdown-item.p-highlight{
   color: #495057!important;
   background: transparent!important;
}
</style>