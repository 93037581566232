<template>
    <main class="auth-bg flex justify-content-center align-items-center">
        <The-Card class="m-auto w-4">
            <template #title>
                
            </template>
            <template #content>
                <div class="grid">
                    <div class="col-12">
                        <h3>
                            註冊用戶 👋
                        </h3>
                        <span class="p-float-label mb-4">
                            <InputText id="username" type="text" class="w-full p-inputtext-sm" />
                            <label for="username">姓名</label>
                        </span>
                        <span class="p-float-label mb-4">
                            <InputText id="username" type="text" class="w-full p-inputtext-sm" />
                            <label for="username">電郵地址</label>
                        </span>
                        <span class="p-float-label p-input-icon-right w-full mb-5">
                            <i class="pi pi-eye" />
                            <InputText id="password" type="text" class="w-full p-inputtext-sm" />
                            <label for="password">密碼</label>
                        </span>
                        <p>姓別</p>
                        <SelectButton v-model="gender" :options="options" optionLabel="name" />
                    </div>
                    <div class="col-12 lg:col-6 lg:col-offset-3">
                        <The-Button label="註冊" class="p-button-raised p-button-secondary w-full" />
                    </div>
                </div>   
            </template>
        </The-Card>
    </main>
</template>

<script>
export default {
    data() {
        return {
            gendar: 'M',
            options: [
                {name: '男', value: 'M'},
                {name: '女', value: 'F'}
            ],
        }
    }
}
</script>

<style scoped>
    .auth-bg{
        height: 100vh;
        background-image: url('@/assets/bg-1.jpg');
        background-size: cover;
    }
</style>